//
//
//
//
//
//
//
//
//

import { useStoryblok } from "@storyblok/nuxt-2";
import { useContext } from "@nuxtjs/composition-api";
import { useGetCacheVersion } from '~/modules/storyblok/composables/getCacheVersion';
const __sfc_main = {
  name: "SidebarSocials",

  setup() {
    const {
      $config
    } = useContext();
    const {
      cacheVersion
    } = useGetCacheVersion();
    let socialLinks = null;

    if (process.client) {
      socialLinks = useStoryblok("header/menu/sidebarsocials", {
        version: $config.storyblokMode,
        cv: cacheVersion
      }).story;
    }

    return {
      socialLinks
    };
  }

};
__sfc_main.props = {
  blok: Object
};
export default __sfc_main;
