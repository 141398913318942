//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { focusTrap } from "@storefront-ui/vue/src/utilities/directives/";
import { clickOutside } from "@storefront-ui/vue/src/utilities/directives/";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { isClient } from "@storefront-ui/vue/src/utilities/helpers";
import SfBar from "@storefront-ui/vue/src/components/molecules/SfBar/SfBar.vue";
import SfCircleIcon from "@storefront-ui/vue/src/components/atoms/SfCircleIcon/SfCircleIcon.vue";
import SfOverlay from "@storefront-ui/vue/src/components/atoms/SfOverlay/SfOverlay.vue";
import SfHeading from "@storefront-ui/vue/src/components/atoms/SfHeading/SfHeading.vue";
import SidebarNews from "./Sidebar/News";
import SidebarSocials from "./Sidebar/Socials";
import SidebarBalance from "./Sidebar/Balance";
import {useStoryblok} from "@storyblok/nuxt-2";
import {useContext} from "@nuxtjs/composition-api";
import { useNoScroll } from '~/composables/useNoScroll';
import { useGetCacheVersion } from '~/modules/storyblok/composables/getCacheVersion';
export default {
  name: "BoldSidebar",
  directives: { focusTrap, clickOutside },
  components: {
    SidebarBalance,
    SidebarSocials,
    SidebarNews,
    SfBar,
    SfCircleIcon,
    SfOverlay,
    SfHeading,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    headingLevel: {
      type: Number,
      default: 3,
    },
    button: {
      type: Boolean,
      default: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    overlay: {
      type: Boolean,
      default: true,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: "left",
      validator: (value) => ["left", "right"].includes(value),
    },
  },
  setup() {
    const { $config } = useContext();
    const { enableScrolling, disableScrolling } = useNoScroll();
    const { cacheVersion } = useGetCacheVersion();
    let sidebarService = null;
    let sidebarAbout = null;

    if (process.client) {
      sidebarService = useStoryblok("header/menu/menuservices", {version: $config.storyblokMode, cv: cacheVersion}).story;
      sidebarAbout = useStoryblok("header/menu/menuabout", {version: $config.storyblokMode, cv: cacheVersion}).story;
    }

    return {
      sidebarService,
      sidebarAbout,
      enableScrolling,
      disableScrolling,
    }
  },
  data() {
    return {
      transition: this.position,
      isOpen: this.visible,
    };
  },
  computed: {
    visibleOverlay() {
      return this.visible && this.overlay;
    },
    transitionName() {
      return "sf-slide-" + this.transition;
    },
    hasTop() {
      return this.$slots.hasOwnProperty("content-top");
    },
    hasBottom() {
      return this.$slots.hasOwnProperty("content-bottom");
    },
  },
  watch: {
    visible: {
      handler(value) {
        if (!isClient) return;
        if (value) {
          this.isOpen = true;
          this.transition = this.position;
          this.$nextTick(() => {
            const sidebarContent = document.getElementsByClassName(
              "sf-mega-menu"
            )[0];
            disableBodyScroll(sidebarContent);
          });
          document.addEventListener("keydown", this.keydownHandler);
          if (document) {
            this.disableScrolling();
          }
        } else {
          clearAllBodyScrollLocks();
          document.removeEventListener("keydown", this.keydownHandler);
          this.isOpen = false;
          if (document) {
            this.enableScrolling();
          }
        }
      },
      immediate: true,
    },
    isOpen: {
      // handle out animation for async load component
      handler(value) {
        if (!isClient) return;
        if (!value) {
          this.transition = this.position === "right" ? "left" : "right";
        }
      },
    },
  },
  beforeDestroy() {
    clearAllBodyScrollLocks();

    if (document) {
      document.removeEventListener("keydown", this.keydownHandler);
      this.enableScrolling();
    }
  },
  methods: {
    close() {
      this.isOpen = false;
      this.$emit("close");
      if (document) {
        this.enableScrolling();
      }
    },
    checkPersistence() {
      if (!this.persistent) this.close();
    },
    keydownHandler(e) {
      if (e.key === "Escape" || e.key === "Esc" || e.keyCode === 27) {
        this.close();
      }
    },
  },
};
