import {computed, reactive, useRoute, watch} from '@nuxtjs/composition-api';

const searchBarTextPlaceHolderFocused = "";
const searchBarTextPlaceHolderUnfocused = "Cosa stai cercando?";

const state = reactive({
    topOffset: 100,
    botOffset: 100,
    resizeListenerInitialized: false
});
export function useHeaderOffsets() {

    const initWindowEvents = () => {
        if (typeof window === 'undefined' || !window) return;

        if (!state.resizeListenerInitialized) {
            window.addEventListener("resize", function () {
                setState();
            })

            // Observe when the header or bottom navigation changes dimensions for some reason
            const header = document.querySelector("header");
            const bottom = document.querySelector(".sf-bottom-navigation.navigation-bottom");
            const observer = new ResizeObserver(setState);
            observer.observe(header);
            observer.observe(bottom);

            setState();
        }
    }

    const setState = () => {
        const header = document.querySelector("header");
        const headerOffset = header.clientHeight;
        const bottom = document.querySelector(".sf-bottom-navigation.navigation-bottom");
        const bottomOffset = bottom.clientHeight;
        state.topOffset = headerOffset;
        state.botOffset = bottomOffset;
    }

    return {
        topOffset: computed(() => state.topOffset),
        botOffset: computed(() => state.botOffset),
        resizeListenerInitialized: computed(() => state.resizeListenerInitialized),
        setState,
        initWindowEvents
    };
}
