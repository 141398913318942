//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SfLink from "@storefront-ui/vue/src/components/atoms/SfLink/SfLink.vue";
import SfButton from "@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue";

export default {
  name: "BoldMenuItemAll",
  components: {
    SfLink,
    SfButton
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    link: {
      type: [String, Object],
      default: null,
    },
  },
  computed: {
    bind() {
      const bind = {};
      if (this.link) {
        bind.link = this.link;
      } else {
        bind.class = "sf-button--pure";
      }
      return bind;
    },
    componentIs() {
      return this.link ? "SfLink" : "SfButton";
    },
  },
};
