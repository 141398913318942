//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SfLink from "@storefront-ui/vue/src/components/atoms/SfLink/SfLink.vue";
import SfButton from "@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue";

export default {
  name: "BoldMenuItem",
  components: {
    SfLink,
    SfButton,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    link: {
      type: [String, Boolean],
      default: false,
    },
    isLink: {
      type: [Boolean],
      default: false,
    },
  },
  computed: {
    bind() {
      const bind = {};
      if (this.isLink) {
        bind.link = this.link;
      } else {
        bind.class = "sf-button--pure";
      }
      return bind;
    },
    componentIs() {
      return this.isLink ? "SfLink" : "SfButton";
    },
  },
};
